import Storage from "./storage";
import HttpHelper from "./http-helper";
import history from "../routes/history";
import axios from "axios";
import $ from "jquery";
class Utils {
  constructor() {
    this.httpHelper = new HttpHelper();
    this.storage = new Storage();
  }

  getAll() {
    return {
      httpHelper: this.httpHelper,
      storage: this.storage,
    };
  }

  static getNameInitials = (name) => {
    const initials = name.match(/\b\w/g) || [];

    return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  };

  /**
   * Get user data object
   * @returns route
   */
  static getUserDataObject = () => {
    return (
      JSON.parse(localStorage.getItem("userData")) &&
      JSON.parse(localStorage.getItem("userData"))
    );
  };

  static isquickeasyfinance = () => {
    return (
      window.location.host === "www.quickeasyfinance.com.au" ||
      window.location.host === "quickeasyfinance.com.au"
    );
  };

  static getSurveyUrl = () => {
    return window.location.host === "www.quickandeasyhearing.com" ||
      window.location.host === "quickandeasyhearing.com"
      ? "/ClaimMyHearingExperience&transaction_id=1028d3170d398ac5ebeac0ec452315"
      : window.location.host === "www.mysolarcompare.com.au" ||
        window.location.host === "mysolarcompare.com.au"
        ? "https://tracking.popsplot.com.au/aff_c?offer_id=6569&aff_id=8268&url_id=25135"
        : window.location.host === "compare1.mysolarcompare.com.au" ||
          window.location.host === "www.compare1.mysolarcompare.com.au"
          ? "https://www.3quotes.com.au/quotes.html?source=87934"
          : window.location.host === "compare2.mysolarcompare.com.au" ||
            window.location.host === "www.compare2.mysolarcompare.com.au"
            ? "http://tracking.popsplot.com.au/aff_c?offer_id=6569&aff_id=8220"
            : window.location.host === "compare3.mysolarcompare.com.au" ||
              window.location.host === "www.compare3.mysolarcompare.com.au"
              ? "http://tracking.popsplot.com.au/aff_c?offer_id=6569&aff_id=8268"
              : window.location.host === "deals.myenergycompare.com.au" ||
                window.location.host === "www.deals.myenergycompare.com.au"
                ? "https://tracking.popsplot.com.au/aff_c?offer_id=6261&aff_id=5124"
                : window.location.host === "new.myenergycompare.com.au" ||
                  window.location.host === "www.new.myenergycompare.com.au"
                  ? "http://tracking.popsplot.com.au/aff_c?offer_id=5965&aff_id=8268&url_id=25486"
                  : window.location.host === "www.supportwithdebt.com.au" ||
                    window.location.host === "supportwithdebt.com.au" ||
                    window.location.host === "www.abcb.supportwithdebt.com.au" ||
                    window.location.host === "abcb.supportwithdebt.com.au" ||
                    window.location.host === "www.debtrelief.supportwithdebt.com.au" ||
                    window.location.host === "debtrelief.supportwithdebt.com.au" ||
                    window.location.host === "www.ds.supportwithdebt.com.au" ||
                    window.location.host === "ds.supportwithdebt.com.au"
                    ? "/reduce_my_debt_now"
                    : window.location.host === "tutorexpert.com.au" ||
                      window.location.host === "www.tutorexpert.com.au"
                      ? "/getstarted"
                      : window.location.host === "www.gs.myenergycompare.com.au" ||
                        window.location.host === "gs.myenergycompare.com.au"
                        ? "/comparenow?utm_medium=email&utm_source=acqedm"
                        : window.location.host === "seq.mysolarcompare.com.au" ||
                          window.location.host === "www.seq.mysolarcompare.com.au"
                          ? "https://www.3quotes.com.au/quotes.html?source=87934"
                          : window.location.host === "healthdeal.myhealthcompare.com.au" ||
                            window.location.host === "www.healthdeal.myhealthcompare.com.au"
                            ? "/adwords"
                            : window.location.host === "www.solarmarket.mysolarcompare.com.au" ||
                              window.location.host === "solarmarket.mysolarcompare.com.au"
                              ? "https://www.solarmarket.com.au/get-free-quotes-and-solar-guide-now/?utm_source=acquirely&utm_medium=affiliate&utm_content=native&utm_campaign=pub-id"
                              : window.location.host ===
                                "www.govthearingaids.quickandeasyhearing.com" ||
                                window.location.host === "govthearingaids.quickandeasyhearing.com"
                                ? "/ClaimMyHearingExperience&transaction_id=1028d3170d398ac5ebeac0ec452315"
                                : window.location.host === "www.firstnational.ozhouseprice.com.au" ||
                                  window.location.host === "firstnational.ozhouseprice.com.au"
                                  ? "/freeappraisal?source=native"
                                  : "/comparenow";
  };

  static getBlogUrl = () => {
    return window.location.host === "compare1.mysolarcompare.com.au" ||
      window.location.host === "www.compare1.mysolarcompare.com.au" ||
      window.location.host === "compare2.mysolarcompare.com.au" ||
      window.location.host === "www.compare2.mysolarcompare.com.au" ||
      window.location.host === "compare3.mysolarcompare.com.au" ||
      window.location.host === "www.compare3.mysolarcompare.com.au"
      ? "/solarsavings"
      : window.location.host === "seq.mysolarcompare.com.au" ||
        window.location.host === "www.seq.mysolarcompare.com.au"
        ? "/solarsavings"
        : window.location.host === "myhealthcompare.com.au" ||
          window.location.host === "www.myhealthcompare.com.au"
          ? "/aussie-seniors-saving-big-on-health-insurance"
          : window.location.host === "www.myenergycompare.com.au" ||
            window.location.host === "myenergycompare.com.au"
            ? "/govt-says-to-compare-plans"
            : window.location.host === "www.new.myenergycompare.com.au" ||
              window.location.host === "new.myenergycompare.com.au" ||
              window.location.host === "www.gs.myenergycompare.com.au" ||
              window.location.host === "gs.myenergycompare.com.au"
              ? "/groundbreaking-new-energy-service"
              : window.location.host === "deals.myenergycompare.com.au" ||
                window.location.host === "www.deals.myenergycompare.com.au"
                ? "/dealofthemonth"
                : window.location.host === "mylifeinsurancecompare.com.au" ||
                  window.location.host === "www.mylifeinsurancecompare.com.au" ||
                  window.location.host === "nobleoak.mylifeinsurancecompare.com.au" ||
                  window.location.host === "www.nobleoak.mylifeinsurancecompare.com.au"
                  ? "/benefits-of-life-insurance"
                  : window.location.host === "myhomeloancompare.com.au" ||
                    window.location.host === "www.myhomeloancompare.com.au"
                    ? "/howtosavebig"
                    : window.location.host === "www.solarmarket.mysolarcompare.com.au" ||
                      window.location.host === "solarmarket.mysolarcompare.com.au"
                      ? "/large_government_solar_rebates"
                      : window.location.host === "tutorexpert.com.au" ||
                        window.location.host === "www.tutorexpert.com.au"
                        ? "/cluey-learning-tutor-expert"
                        : "/blog_page";
  };

  static hideLogoLink = (homeData) => {
    return homeData.is_logo_link === "no";
  };

  static reverseOrderAdBanner = (blogData) => {
    return blogData?.is_banner_ad === "yes";
  };

  static hideFirstAdBanner = (blogData) => {
    return blogData?.is_first_banner === "yes";
  };

  static hideHomeLink = () => {
    return true;
  };

  static hideSurveyPageLink = (landing) => {
    return landing?.is_survey_page_link?.toLowerCase() === "no";
  };

  static getPageContent = (siteData, pageid) => {
    return siteData?.content_manage_sites?.filter((item) => {
      return item.page_id === pageid;
    })[0];
  };

  static getBannerContent = (siteData) => {
    return (
      siteData?.banners && siteData?.banners?.length > 0 && siteData?.banners[0]
    );
  };

  static getDynamciFormContent = (siteData, pageid) => {
    return (
      siteData?.dynamic_forms &&
      siteData?.dynamic_forms?.filter((item) => {
        return item.page_id === pageid;
      })[0]
    );
  };

  //not used
  static getPageRedirectionFromSiteData = (getSiteDataObject) => {
    //1. if no other landing page, send null
    //2. if landing page send it's link back
    try {
      var mainPageContent = Utils.getPageContent(getSiteDataObject, 1);
      const redirect_to_survey = mainPageContent.redirect_to_survey;
      const redirect_to_blog = mainPageContent.redirect_to_blog;

      if (redirect_to_survey.toLowerCase() === "yes") {
        let surveyPageObject = Utils.getPageContent(getSiteDataObject, 6);

        if (surveyPageObject) {
          return surveyPageObject.page_url;
        }
      }

      if (redirect_to_blog.toLowerCase() === "yes") {
        let blogPageObject = Utils.getPageContent(getSiteDataObject, 8);

        if (blogPageObject) {
          return blogPageObject.page_url;
        }
      }

      return null;
    } catch (error) {
      console.log("error", error);
      return "";
    }
  };

  static redirectToBlogOnPageLoad = (getSiteDataObject) => {
    var mainPageContent = Utils.getPageContent(getSiteDataObject, 1);
    const redirect_to_blog = mainPageContent?.redirect_to_blog;
    if (redirect_to_blog?.toLowerCase() === "yes") {
      let blogPageObject = Utils.getPageContent(getSiteDataObject, 8);

      if (blogPageObject) {
        return blogPageObject.page_url;
      }
    }
    return "";
  };

  static redirectToSurveyOnPageLoad = (getSiteDataObject) => {
    var mainPageContent = Utils.getPageContent(getSiteDataObject, 1);
    const redirect_to_survey = mainPageContent?.redirect_to_survey;
    if (redirect_to_survey?.toLowerCase() === "yes") {
      let surveyPageObject = Utils.getPageContent(getSiteDataObject, 6);

      if (surveyPageObject) {
        return surveyPageObject.page_url;
      }
    }
    return "";
  };

  //Removed on 29 march 2021, on request LP-437
  static showFinishButtonOneStepEarly = () => {
    return (
      (window.location.host === "www.ds.supportwithdebt.com.au" ||
        window.location.host === "ds.supportwithdebt.com.au") &&
      1 === 2
    );
  };

  static showPrivacyPolicyTextUnderSubmitButton = (showInPopup) => {
    return window.location.host === "www.myhomeloancompare.com.au" ||
      window.location.host === "myhomeloancompare.com.au"
      ? true
      : showInPopup === "no";
  };

  static TextOnSubmit = () => {
    return window.location.host ===
      "www.creditcounsellors.supportwithdebt.com.au" ||
      window.location.host === "creditcounsellors.supportwithdebt.com.au"
      ? `Calculate my savings`
      : window.location.host === "propertyvalue.ozhouseprice.com.au" ||
        window.location.host === "www.propertyvalue.ozhouseprice.com.au"
        ? `Get a Free Appraisal`
        : window.location.host === "kiwihouseprice.com" ||
          window.location.host === "www.kiwihouseprice.com"
          ? `Get a Free Appraisal`
          : window.location.host === "compare.myhealthcompare.com.au" || 
            window.location.host === "www.compare.myhealthcompare.com.au" ||
            window.location.host === "myhealthcompare.com.au" ||
            window.location.host === "www.myhealthcompare.com.au"
            ? `COMPARE POLICIES NOW`
            : "Submit";
  };

  static showDisclaimerTextInPopup = (showInPopup) => {
    return window.location.host === "www.healthdeal.myhealthcompare.com.au" ||
      window.location.host === "healthdeal.myhealthcompare.com.au"
      ? true
      : showInPopup === "yes";
  };
  static showPoweredByLogoOnForm = () => {
    return (
      window.location.host === "mylifeinsurancecompare.com.au" ||
      window.location.host === "www.mylifeinsurancecompare.com.au"
    );
  };

  static showJustCompareLogoOnForm = () => {
    return (
      window.location.host === "justcompare.myenergycompare.com.au" ||
      window.location.host === "www.justcompare.myenergycompare.com.au"
    );
  };

  static showJoustLogo = () => {
    return (
      window.location.host === "2022.myhomeloancompare.com.au" ||
      window.location.host === "www.2022.myhomeloancompare.com.au"
    );
  };

  static greenGoldMap = () => {
    return (
      window.location.host === "www.seq.mysolarcompare.com.au" ||
      window.location.host === "seq.mysolarcompare.com.au" ||
      window.location.host === "www.mysolarcompare.com.au" ||
      window.location.host === "mysolarcompare.com.au"
    );
  };

  static goldGreenMap = () => {
    const host = Utils.getHost().websiteUrl;
    return (
      host === "businessenergydeals.com.au" ||
      host === "www.businessenergydeals.com.au" ||
      host === "www.savings.mysolarcompare.com.au" ||
      host === "savings.mysolarcompare.com.au" ||
      host === "www.aus.quickandeasyhearing.com" ||
      host === "aus.quickandeasyhearing.com" ||
      host === "govtsolarrebates.mysolarcompare.com.au" ||
      host === "www.govtsolarrebates.mysolarcompare.com.au" ||
      host === "joust.myhomeloancompare.com.au" ||
      host === "www.joust.myhomeloancompare.com.au" ||
      host === "cbhs.myhealthcompare.com.au" ||
      host === "www.cbhs.myhealthcompare.com.au" ||
      host === "www.hca.quickandeasyhearing.com" ||
      host === "hca.quickandeasyhearing.com" ||
      host === "www.ctm.myhealthcompare.com.au" ||
      host === "ctm.myhealthcompare.com.au" ||
      host === "www.compare.myhealthcompare.com.au" ||
      host === "compare.myhealthcompare.com.au" ||
      host === "www.hca2.quickandeasyhearing.com" ||
      host === "hca2.quickandeasyhearing.com"
    );
  };

  static googleReviewImageWithoutGoogleText = () => {
    return (
      window.location.host === "www.businessfundstoday.net" ||
      window.location.host === "businessfundstoday.net"
    );
  };

  static renderFont = (getSiteDataObject) => {
    $("body, html").css("font-family", getSiteDataObject.subfooter_color);
  };

  static renderBingPixel2022SupportWithDebt = (script) => {
    if (
      window.location.host === "www.2022.supportwithdebt.com.au" ||
      window.location.host === "2022.supportwithdebt.com.au" ||
      window.location.host === "v22022.supportwithdebt.com.au" ||
      window.location.host === "www.v22022.supportwithdebt.com.au" ||
      window.location.host === "debtrepair.financesolver.co" ||
      window.location.host === "www.debtrepair.financesolver.co"
    ) {
      $("head").append(`${script}`);
    }
  };

  static callBingPixel2022SupportWithDebt = () => {
    if (
      window.location.host === "www.2022.supportwithdebt.com.au" ||
      window.location.host === "2022.supportwithdebt.com.au" ||
      window.location.host === "v22022.supportwithdebt.com.au" ||
      window.location.host === "www.v22022.supportwithdebt.com.au" ||
      window.location.host === "debtrepair.financesolver.co" ||
      window.location.host === "www.debtrepair.financesolver.co"
    ) {
      window["uet_report_conversion"]();
    }
  };

  static notShowVerifyingProgressBar = () => {
    return true;
  };

  static historyPush = (pageurl) => {
    const queryParam = history.location.search || "";
    history.push(`${pageurl}${queryParam}`);
  };

  static windowOpen = (pageurl, target) => {
    const queryParam = history.location.search;
    window.open(`${pageurl}${queryParam}`, target || "_self");
  };

  static handleClickUrl = (page_url, is_external, target) => {
    if (
      page_url?.includes("http") ||
      page_url?.includes("https") ||
      is_external === "true" ||
      target === "true"
    ) {
      this.windowOpen(page_url, target === "true" ? "_blank" : "_self");
    } else {
      this.historyPush(`${page_url}`);
    }
  };

  static getData = async () => {
    // const res = await axios.get("https://geolocation-db.com/json/");
    const res = await axios.get("https://app.yourbooks.in/ipgeo?apikey=AIzaSyATccdC3GMIYDfvjrouSZFUs1Jjld1rOt4");
    return res.data.ip;
  };

  static getHost = () => {
    // console.log("process",process.env);
    let host = {
      apiUrl: process.env.WDS_SOCKET_PATH === "release" ? "https://helathinsurancefind.com.au/api/" : "http://localhost:8034/api/",
      websiteUrl: window.location.host
    };
    return host;
  };
}

export default Utils;
