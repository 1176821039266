import React from "react";
import InputField from "../../views/input-field/input-field";
import NumberField from "../../views/number-field";
import constants from "../../constants";
import Button from "../../views/button";
import { button_appearances } from "../../views/button";
import RangeSlider from "../../views/range-slider";
import SelectBox from "../../views/dropdown/index";
import ComboBox from "../../views/combo-box";
import RadioBox from "../../views/radio-box/index";
import CheckBox from "../../views/check-box/index";
import DateField from "../../views/date-field";
import FileField from "../../views/file-field/file-field";
import TextArea from "../../views/text-area/index";
import {
  leadData,
  leadDatACALLBACK,
} from "../../actions/manage-site-action-type";
import { connect } from "react-redux";
import axios from "axios";
import profanityWords from "../../assets/profanityWords.json";
import DynmicDropDownAutocomplete from "../../views/drop-down-auto-complete/DropdownAndAutoComplete";
import DropDownAutocomplete from "../../views/drop-down-auto-complete/DropdownAndAutoComplete";
import AutoCompletePostSuburb from "../../views/drop-down-auto-complete/autocomplete-post-suburb";
import "./dynamic-form.scss";
import Utils from "../../utils";
import themecolors from "../../utils/themecolors";
import Otpcard from "../../views/card/otpcard";
import AdvertlyPlugin from "../shared/advertly-plugin";
import Card from "../../views/card/card";

class DynamicForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      fieldsData: {},
      error: "",
      isValidate: false,
      isPhoneValid: true,
      popup: false,
      phoneverified: false,
      showOtpPop: false,
      showAdvertly: false,
    };
  }

  componentDidMount = () => {
    const { data } = this.props;

    data.map((item) => {
      if (item.type !== "button" && item.name !== undefined) {
        let returnVal = this.returnValue(item);

        this.setState((prevState, props) => ({
          fields: {
            ...prevState.fields,
            [item.name]: returnVal,
          },
          [`has${item.name}Error`]: returnVal !== "" ? false : true,
        }));
      }
    });
    Utils.getData().then((res) => {
      this.setState({ ipv4: res });
    });
  };

  componentDidUpdate = () => {
    const { isFormClicked, onQBSubmit, theme_id } = this.props;
    if (theme_id === 3 && isFormClicked) {
      const isTrue = this.handleSubmit(this);
      onQBSubmit(false, isTrue);
    }
  };

  returnValue = (item) => {
    var returnValue = "";

    if (item.value) {
      returnValue = item.value;
    } else if (item.values) {
      var valuesArray = [];
      // var temparr = [];
      if (item.type === "select") {
        valuesArray = item.values.filter((item) => {
          return item.selected === true;
        });
      } else if (
        item.type === "autocomplete" &&
        item.className.includes("postcode_suburb")
      ) {
        return returnValue;
      } else {
        valuesArray = item.values.filter((item) => {
          return item.selected === true;
        });
      }

      if (valuesArray.length > 0) {
        valuesArray.map((item) => {
          returnValue = returnValue + "," + item.value;
        });
      } else {
        returnValue = "";
      }
    } else {
      returnValue = "";
    }

    returnValue = returnValue.replace(/^,|,$/g, "");
    return returnValue;
  };

  handleChange = (field, data, e) => {
    const { target } = e;
    const { fields } = this.state;
    fields[field] = target.value;
    this.setState({
      fields,
      [`has${[e.target.name]}Error`]: false,
      isPhoneValid: true,
    });
  };

  handleFileZoneChange = (field, data, e) => {
    const { target } = e;
    const { fields } = this.state;
    fields[field] = target;
    this.setState({ fields });
  };

  comboChange = (value, data, field) => {
    const { fields } = this.state;
    fields[field] = value;
    this.setState({ fields });
  };

  comboClick = (value, field, displayName) => {
    const { fields } = this.state;
    fields[field] = value !== null ? value[displayName] : "";
    this.setState({ fields });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let isTrue = true;
    const { siteData, data, page_id, theme_id, QBData, leadData } = this.props;
    const { fieldsData, fields } = this.state;

    let siteFormData = siteData.dynamic_forms.filter((item) => {
      return item.page_id === page_id;
    })[0];
    let siteSurveyContent = Utils.getPageContent(siteData, 6);

    this.setState({ isValidate: true });

    let formData = [];

    Object.keys(fields).map((key) => {
      var fielddata = data.filter((inner_item) => {
        return inner_item.name === key;
      })[0];

      var formValue = fields[key];
      if (
        fielddata &&
        fielddata.type === "checkbox-group" &&
        fielddata.isOtherSelected &&
        fielddata.isOtherText
      ) {
        formValue = formValue + "," + fielddata.isOtherText;
        formValue = formValue.replace(/^,|,$/g, "");
      }
      if (
        fielddata &&
        fielddata.type === "radio-group" &&
        fielddata.isOtherSelected &&
        fielddata.isOtherText
      ) {
        formValue = fielddata.isOtherText;
      }

      formData.push({
        label: fielddata.label,
        value: formValue,
        name: fielddata.name,
        subtype:
          fielddata.subtype && fielddata.subtype !== undefined
            ? fielddata.subtype
            : fielddata.type,
      });
    });

    fieldsData.data = JSON.stringify(formData);
    fieldsData.domain = window.location.host;
    fieldsData.site_id = siteData.id;
    fieldsData.page_id = page_id;
    fieldsData.form_type = "1";
    fieldsData.site_name = siteData.name;
    fieldsData.interested_in = siteFormData && siteFormData.tag_id;
    const thankyou = Utils.getPageContent(siteData, 7);
    fieldsData.thankyou_page_url = thankyou?.page_url || "/";

    let fullName = "";
    let i, len;
    let fName = "";
    let lName = "";

    for (i = 0, len = formData.length; i < len; i++) {
      let tname = formData[i].name ? formData[i].name.toLowerCase() : "";

      if (tname === "name" || tname === "fullname" || tname === "full-name") {
        fullName = formData[i].value;
      }

      if (
        tname === "firstname" ||
        tname === "fname" ||
        tname === "first-name"
      ) {
        fName = formData[i].value;
      }

      if (tname === "lastname" || tname === "lname" || tname === "last-name") {
        lName = formData[i].value;
      }
      if (tname === "address") {
        fieldsData["address"] = formData[i].value;
      }
      if (
        fieldsData.domain === "quickeasyfinance.com.au" ||
        fieldsData.domain === "www.quickeasyfinance.com.au"
      ) {
        if (tname === "permanent_employee") {
          fieldsData["permanent_employee"] = formData[i].value;
        }
        if (tname === "loan_amount") {
          fieldsData["loan_amount"] = formData[i].value;
        }
        if (tname === "address") {
          fieldsData["address"] = formData[i].value;
        }

        if (tname === "loan_term") {
          fieldsData["loan_term"] = formData[i].value;
        }
        if (tname === "age") {
          fieldsData["ages"] = formData[i].value;
        }
        if (tname === "own_car") {
          fieldsData["own_car"] = formData[i].value;
        }
        if (tname === "citizen") {
          fieldsData["citizen"] = formData[i].value;
        }
      }
      if (
        tname === "dob" ||
        tname === "dateofbirth" ||
        tname === "date-of-birth"
      ) {
        let date = formData[i].value;
        if (date instanceof Date && !isNaN(date)) {
          fieldsData["dob"] = date;

          var today = new Date();
          var birthDate = new Date(date);
          var age_now = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
          }

          fieldsData["age"] = age_now;
        }
      }

      if (tname === "gender" || tname === "sex") {
        fieldsData["gender"] = formData[i].value;
      }

      if (formData[i].subtype === "email") {
        fieldsData["email"] = formData[i].value;
      }

      if (formData[i].subtype === "tel") {
        fieldsData["phone_number"] = formData[i].value;
      }

      if (
        tname === "town_city" ||
        tname === "towncity" ||
        tname === "town" ||
        tname === "city"
      ) {
        fieldsData["town_city"] = formData[i].value;
      }

      if (
        tname === "post_code" ||
        tname === "postcode" ||
        tname === "zip_code" ||
        tname === "zip"
      ) {
        fieldsData["postcode"] = formData[i].value;
      }

      if (tname === "state") {
        fieldsData["state"] = formData[i].value;
      }

      if (this.state[`has${formData[i].name}Error`] === true) {
        isTrue = false;
        break;
      }
    }

    if (fullName) {
      fieldsData.full_name = fullName.trim(" ");
    } else {
      fieldsData.full_name = fName.trim(" ") + " " + lName.trim(" ");
    }

    localStorage.setItem("full_name", fieldsData.full_name);

    fieldsData.theme_id = theme_id;
    fieldsData.qb_data = JSON.stringify(QBData);
    fieldsData.fName = fName.trim(" ");
    fieldsData.lName = lName.trim(" ");
    fieldsData.ipv4 = this.state.ipv4;
    fieldsData.pn = "dynamic-form";

    var isIncludes = false;
    try {
      formData &&
        formData.forEach((item) => {
          profanityWords.list.forEach((word) => {
            if (
              item.value !== undefined &&
              word !== undefined &&typeof( item.value) === "string"&&
              this.isIncludeProfanityWord(
                word.toLowerCase(),
                item.value.toLowerCase()
              )
            ) {
              isIncludes = true;
            }
          });
        });
    } catch (e) {
      console.log(e);
    }

    if (isTrue) {
      this.redirectToDeclinePageIfNotSatisfiesCondition(
        formData,
        thankyou?.page_url || "/thank-you"
      );
      if (!isIncludes) {
        localStorage.setItem("email", fieldsData["email"]);
        // console.log(fieldsData);
        this.handlecard();
        if (
          fieldsData["phone_number"] !== "" &&
          fieldsData["phone_number"] !== undefined &&
          fieldsData["phone_number"] !== null
        ) {
          this.validatePhone(fieldsData, this);
        } else {
          siteSurveyContent.section_second_header !== "yes" &&
            this.handleSuccessCallback(false);
        }
      } else {
        localStorage.setItem("email", "");
        window.location.href = thankyou?.page_url || "/thank-you";
      }
    }
    return isTrue;
  };

  handlecard = () => {
    const { siteData } = this.props;
    let siteSurveyContent = Utils.getPageContent(siteData, 6);
    siteSurveyContent.section_second_header === "yes"
      ? setTimeout(() => {
          this.setState({ showOtpPop: true });
        }, [500])
      : this.setState({ isLoading: true });
  };

  redirectToDeclinePageIfNotSatisfiesCondition = (formData, thankyou_url) => {
    localStorage.setItem("quickandeasyFinnace", "NA");
    if (Utils.isquickeasyfinance()) {
      formData.map((item) => {
        if (
          (item.name.toLowerCase() === "age" ||
            item.name.toLowerCase() === "citizen") &&
          item.value.toLowerCase() === "no"
        ) {
          localStorage.setItem(
            "quickandeasyFinnace",
            "Thank you for your enquiry with Quick and Easy Finance. Though unfortunately based on your current circumstances, we are not able to assist you at this point in time" +
              "Why not find out about a quick and easy loans with Ferratum? A cash loan with a decision in only a few minutes! Click here to find out more"
          );
          window.location.href = thankyou_url || "/thank-you";
          return;
        }
      });
    }
  };

  isIncludeProfanityWord = (str, word) => {
    //return new RegExp("\\b" + word + "\\b", "i").test(str);
    return str === word;
  };

  validatePhone = (fieldsData, thiss) => {
    const { siteData } = this.props;
    let siteSurveyContent = Utils.getPageContent(siteData, 6);
    try {
      if (fieldsData.phone_number !== "") {
        fieldsData.phone_number = fieldsData.phone_number
          .replace("+61", "0")
          .replace("+64", "0")
          .replace("+1", "").
          replace("+44", "0");
        var isSuccess = false;
        var baseurl = "";
        if (process.env.WDS_SOCKET_PATH === "release") {
          baseurl = "https://helathinsurancefind.com.au/api/";
        } else {
          baseurl = "http://localhost:8034/api/";
        }
        var config = {
          method: "get",
          url: `${baseurl}neutrinoapiV3/${fieldsData.phone_number}/${siteData.country}/${siteData.id}`,
        };

        axios(config)
          .then(function (response) {
            isSuccess =
              response && response?.data && response?.data?.message === "true";

            var stateData = thiss.state;

            var statedata =
              stateData &&
              stateData.fieldsData &&
              stateData.fieldsData.data &&
              JSON.parse(stateData.fieldsData.data);

            var phonedata =
              statedata &&
              statedata.filter((item) => {
                return item.subtype === "tel";
              });
            var phonevalidate = phonedata.length > 0 && phonedata[0].name;

            if (isSuccess) {
              thiss.setState({
                isValidate: false,
                isPhoneValid: true,
                [`has${[phonevalidate]}Error`]: false,
              });
              thiss.handlecard();

              siteSurveyContent.section_second_header !== "yes" &&
                thiss.handleSuccessCallback(false);
            }

            if (!isSuccess) {
              if (
                phonevalidate !== undefined &&
                phonevalidate !== null &&
                phonevalidate !== ""
              ) {
                thiss.setState({
                  isPhoneValid: false,
                  isValidate: true,
                  [`has${[phonevalidate]}Error`]: true,
                  isLoading: false,
                });
              }
            }
          })
          .catch(function (error) {
            console.log("validatePhoneTwilio exception", error);
            thiss.handlecard();
            siteSurveyContent.section_second_header !== "yes" &&
              thiss.handleSuccessCallback(false);
          });
      }
    } catch (ex) {
      console.log("validatePhoneTwilio exception", ex);
      thiss.handlecard();
      siteSurveyContent.section_second_header !== "yes" &&
        thiss.handleSuccessCallback(false);
    }
  };

  headerTag = (item, index) => (
    <div key={index} style={{ display: "flex" }}>
      <item.subtype>{item.label}</item.subtype>{" "}
    </div>
  );

  buttonTag = (item, index) => {
    const { theme_id, surveyLayout, setformstate } = this.props;
    let siteFormData = this.props.siteData.content_manage_sites.filter(
      (item) => {
        return item.page_id === 6;
      }
    )[0];
    return (
      <div
        className={`space-between`}
        key={index}
        style={
          theme_id === 2 ? {} : { display: "flex", justifyContent: "center" }
        }
      >
        <div
          style={
            theme_id === 2
              ? {}
              : {
                  display: "flex",
                  width: surveyLayout !== "thirdlayout" ? "200px" : undefined,
                }
          }
          className={
            surveyLayout === "thirdlayout" || theme_id === 2
              ? "standard_button_fullwidth"
              : undefined
          }
        >
          {theme_id === 2 ? (
            <input
              type="button"
              className={`btn btn-block btn-lg acceptBnt`}
              onClick={this.handleSubmit}
              value={item.label}
            />
          ) : (
            <Button
              type={item.subtype}
              name={item.name}
              appearance={
                item.style
                  ? theme_id === "4"
                    ? button_appearances.info_fifth
                    : button_appearances[item.style]
                  : button_appearances.primary_large
              }
              clickHandler={this.handleSubmit}
              text={item.label}
            />
          )}
        </div>
      </div>
    );
  };

  dropDownAutocompleteOnChange = (data, name) => {
    const { fields } = this.state;
    fields[name] = data.value;
    this.setState({ fields, [`has${[name]}Error`]: false });
  };

  DynmicDropDownAutocomplete = (item, index) => {
    const { isValidate, fields } = this.state;

    const { siteData, formtype } = this.props;
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    return (
      <div
        className="space-between"
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DynmicDropDownAutocomplete
          validate={isValidate}
          theme_id={theme}
          validateOptions={{
            check: true,
            required: true,
          }}
          type={formtype}
          options={item.values}
          name={item.name}
          label={item.label}
          handleChange={(value) =>
            this.dropDownAutocompleteOnChange(value, item.name)
          }
          description={item.description}
          allowMultiple={item.multiple}
          validationCallback={(res) => {
            this.setState({ [`has${item.name}Error`]: res, isValidate: false });
          }}
          value={fields[item.name] || ""}
          requireValidOption={item.requireValidOption}
          iconName={item.icon}
          placeholder={item.placeholder}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
        />
      </div>
    );
  };
  selectTag = (item, index) => {
    const { theme_id, page_id } = this.props;

    return (
      <div
        className={`space-between ${
          theme_id === "4" && page_id === "1" ? item.className : ""
        }`}
        key={index}
        style={{ display: "flex" }}
      >
        <SelectBox
          theme_id={theme_id}
          options={item.values}
          name={item.name}
          label={item.label}
          iconName={item.icon}
          fieldAttributes={{
            onChange: this.handleChange.bind(this, item.name, item),
          }}
          description={item.description}
          validateOptions={{
            check: true,
            required: item.required,
          }}
          allowMultiple={item.multiple}
        />
      </div>
    );
  };

  handleChangeRadio = (e, data) => {
    const { fields } = this.state;
    fields[e.target.name] = e.target.value;
    data.isOtherSelected = false;
    data.isOtherText = "";

    var status = e.target.value === "other" ? true : false;
    this.setState({ fields, [`has${[e.target.name]}Error`]: status });
  };

  handleChangeRadioOther = (otherTxt, isSelected, data) => {
    data.isOtherSelected = isSelected;
    data.isOtherText = otherTxt;

    const { fields } = this.state;
    fields[data.name] = "other";

    var status = otherTxt === "" ? true : false;
    this.setState({ fields, [`has${[data.name]}Error`]: status });
  };

  handleRangeSliderChange = (name, value) => {
    const { fields } = this.state;
    fields[name] = value;
    this.setState({
      name,
      [`has${[name]}Error`]: false,
      isPhoneValid: true,
    });
  };

  radioboxTag = (item, index) => {
    const { isValidate, fields } = this.state;
    const { theme_id, page_id } = this.props;
    const { siteData } = this.props;

    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    return (
      <div
        className={`space-between ${
          theme_id === "4" && page_id === "1" ? item.className : ""
        }`}
        key={index}
        style={{ display: "flex" }}
      >
        <RadioBox
          theme_id={theme_id}
          type={"standard"}
          data={item}
          key={index}
          value={fields[item.name] || ""}
          name={item.name}
          onChangeHandle={(res) => this.handleChangeRadio(res, item)}
          validateOptions={{
            required: item.required,
          }}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${item.name}Error`]: res, isValidate: false })
          }
          onChangeOther={(res, res1) =>
            this.handleChangeRadioOther(res, res1, item)
          }
          iconName={"icon-date"}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
        />
      </div>
    );
  };

  AutocompleteTag = (item, index) => {
    const { fields, isValidate } = this.state;
    const { theme_id, page_id } = this.props;

    return (
      <div
        className={`space-between ${
          theme_id === "4" && page_id === "1" ? item.className : ""
        }`}
        key={index}
      >
        <ComboBox
          theme_id={theme_id}
          clearOnEscape={true}
          classname={item.className}
          name={item.name}
          arrList={item.values}
          label={item.label}
          description={item.description}
          handleChange={(value) => this.comboChange(value, item.name, item)}
          handelClickEvent={(value) =>
            this.comboClick(value, item.name, "value")
          }
          validateOptions={{
            check: true,
            required: item.required,
          }}
          validate={isValidate}
          validationCallback={(res) => {
            this.setState({ [`has${item.name}Error`]: res, isValidate: false });
          }}
          value={fields[item.name] || ""}
          placeholder={item.placeholder}
          requireValidOption={item.requireValidOption}
        />{" "}
      </div>
    );
  };

  paragraphTag = (item, index) => (
    <div key={index} className="space-between">
      <item.subtype key={index}>{item.label}</item.subtype>
    </div>
  );

  getCommaSepratedValuesCheckbox = (data) => {
    var selectedValues = "";
    data &&
      data.values &&
      data.values.map((item) => {
        if (item.selected === true || item.selected === "true") {
          selectedValues += "," + item.label;
        }
      });
    return (selectedValues = selectedValues.replace(/^,|,$/g, ""));
  };

  checkboxErrorStatus = (data, selectedValues) => {
    return data.isOtherSelected && data.isOtherText === ""
      ? true
      : data.isOtherSelected && data.isOtherText !== ""
      ? false
      : selectedValues === ""
      ? true
      : false;
  };

  handleChangeCheckbox = (e, data) => {
    const { fields } = this.state;
    var selectedValues = this.getCommaSepratedValuesCheckbox(data);
    fields[e.target.name] = selectedValues;
    var status = this.checkboxErrorStatus(data, selectedValues);

    this.setState({ fields, [`has${[e.target.name]}Error`]: status });
  };

  handleChangeCheckboxOther = (otherTxt, isSelected, data) => {
    data.isOtherSelected = isSelected;
    data.isOtherText = otherTxt;
    var selectedValues = this.getCommaSepratedValuesCheckbox(data);
    var status = this.checkboxErrorStatus(data, selectedValues);

    this.setState({ [`has${[data.name]}Error`]: status });
  };

  checkboxTag = (item, index) => {
    const { fields, isValidate } = this.state;
    const { theme_id, page_id } = this.props;
    const { siteData } = this.props;

    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    return (
      <div
        key={index}
        className={`space-between ${
          theme_id === "4" && page_id === "1" ? item.className : ""
        }`}
      >
        <CheckBox
          theme_id={theme_id}
          label={item.label}
          className={item.className}
          options={item.values || []}
          name={item.name}
          validateOptions={{
            required: item.required,
          }}
          validate={isValidate}
          toggle={item.toggle}
          inline={item.inline}
          other={item.other}
          validationCallback={(res) =>
            this.setState({ [`has${item.name}Error`]: res, isValidate: false })
          }
          id={"isHelpdesk_id"}
          value={fields[item.name] || ""}
          optionText={""}
          isDisabled={false}
          onChange={(res) => this.handleChangeCheckbox(res, item)}
          onChangeOther={(res, res1) =>
            this.handleChangeCheckboxOther(res, res1, item)
          }
          displayName={"title"}
          description={item.description}
          data={item}
          theme={theme}
          iconName={"icon-money2"}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
        />
      </div>
    );
  };

  handleDateChange = (value, field) => {
    const { fields } = this.state;
    fields[field] = value;
    this.setState({ fields, [`has${[field]}Error`]: false });
  };

  dateTag = (data, index) => {
    const { fields, isValidate } = this.state;
    const { theme_id, page_id, siteData } = this.props;
    var theme = this.props.siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    var mindate = new Date("1900-01-01");
    if (data.name === "dob") {
      var maxdate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
    } else {
      var maxdate = new Date(
        new Date().getFullYear() + 100,
        new Date().getMonth(),
        new Date().getDate()
      );
    }
    let isdob =
      data.className.includes("dob") ||
      data.className.includes("DOB") ||
      data.className.includes("Dob");
    if (isdob) {
      maxdate = new Date(
        new Date().getFullYear() - 18,
        new Date().getMonth(),
        new Date().getDate()
      );
    }
    let yearsCount =
      parseInt(maxdate.getFullYear()) - parseInt(mindate.getFullYear());
    return (
      <div
        key={index}
        className={`space-between ${theme_id === 2 ? data.className : ""}`}
      >
        <DateField
          theme_id={theme_id}
          key={index}
          label={data.label}
          description={data.description}
          name={data.name}
          validateOptions={{
            required: data.required,
          }}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${data.name}Error`]: res, isValidate: false })
          }
          fieldAttributes={{
            placeholderText: data.placeholder,
            //onChange: (date) => this.handleDateChange(date, data.name),
            //selected: fields[data.name] || "",
          }}
          onDateChange={(date) => this.handleDateChange(date, data.name)}
          value={fields[data.name] || ""}
          maxdate={maxdate}
          mindate={mindate}
          yearsCount={yearsCount}
          theme={theme}
          iconName={data.icon}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
        />
      </div>
    );
  };

  fileTag = (data, index) => {
    const { fields, isValidate } = this.state;
    switch (data.subtype) {
      case "file":
        return (
          <div key={index} className="space-between">
            <FileField
              key={index}
              label={data.label}
              placeHolder={data.placeholder}
              name={data.name}
              description={data.description}
              validateOptions={{
                required: data.required,
              }}
              multiple={data.multiple}
              validate={isValidate}
              validationCallback={(res) =>
                this.setState({
                  [`has${data.name}Error`]: res,
                  isValidate: false,
                })
              }
              fieldAttributes={{
                autoComplete: "off",
                placeholder: data.placeholder,
                onChange: this.handleChange.bind(this, data.name, data),
                value: fields[data.name] || "",
              }}
            />
          </div>
        );

      default:
        return <></>;
    }
  };

  numberFieldTag = (data, index) => {
    const { fields, isValidate } = this.state;
    const { isFromHome, siteData, theme_id } = this.props;
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    let isRangeSlider =
      data.className.toLowerCase().includes("rangeslider") ||
      data.className.toLowerCase().includes("range-slider");
    var selectedValue = isNaN(fields[data.name])
      ? 0
      : parseInt(fields[data.name]);
    return (
      <div
        key={index}
        className={`space-between ${
          !isRangeSlider && theme_id === 2
            ? data.className !== undefined
              ? data.className
              : ""
            : ""
        }`}
      >
        {isRangeSlider ? (
          <>
            <RangeSlider
              type={"survey"}
              key={index}
              index={index}
              label={data.label}
              placeHolder={data.placeholder}
              name={data.name}
              description={data.description}
              className={data.className}
              min={data.min}
              max={data.max}
              step={data.step}
              theme={theme}
              iconName={data.icon}
              onRangeSliderChange={this.handleRangeSliderChange.bind()}
              value={selectedValue}
              isFromHome={isFromHome}
              themecolors={themecolors.colors(
                theme,
                siteData.theme,
                siteFormData.page_title
              )}
            />
          </>
        ) : (
          <NumberField
            type={"survey"}
            key={index}
            label={data.label}
            placeHolder={data.placeholder}
            name={data.name}
            description={data.description}
            // className={data.className}
            min={data.min}
            max={data.max}
            step={data.step}
            theme={theme}
            iconName={data.icon}
            validateOptions={{
              check: true,
              required: data.required,
            }}
            validate={isValidate}
            validationCallback={(res) =>
              this.setState({
                [`has${data.name}Error`]: res,
                isValidate: false,
              })
            }
            fieldAttributes={{
              autoComplete: "off",
              type: "number",
              placeholder: data.placeholder,
              onChange: this.handleChange.bind(this, data.name, data),
              value: fields[data.name] || "",
            }}
            themecolors={themecolors.colors(
              theme,
              siteData.theme,
              siteFormData.page_title
            )}
            theme_id={siteData.theme}
          />
        )}
      </div>
    );
  };

  textTag = (data, index) => {
    const {
      EMAIL_REGEX,
      MOBILE_NUMBER,
      PASSWORD_REGEX,
      MOBILE_NUMBER_GENERIC,
    } = constants;
    const { fields, isValidate, isPhoneValid } = this.state;
    const { theme_id, page_id, surveyLayout, formtype, siteData } = this.props;
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    switch (data.subtype) {
      case "text":
      case "email":
      case "tel":
      case "password":
        return (
          <div
            key={index}
            className={`space-between ${
              (theme_id === "4" && page_id === "1") || theme_id === 2
                ? data.className !== undefined
                  ? data.className
                  : ""
                : ""
            }`}
          >
            <InputField
              theme_id={theme_id}
              key={index}
              label={data.label}
              placeHolder={data.placeholder}
              name={data.name}
              description={data.description}
              isPhoneValid={isPhoneValid}
              subtype={data.subtype}
              theme={theme}
              iconName={data.icon}
              validateOptions={{
                check: true,
                reg:
                  data.subtype === "email"
                    ? EMAIL_REGEX
                    : data.subtype === "tel"
                    ? siteData.country !== "AU"
                      ? MOBILE_NUMBER_GENERIC
                      : MOBILE_NUMBER
                    : data.subtype === "password"
                    ? PASSWORD_REGEX
                    : "",
                regMsg:
                  data.subtype === "email"
                    ? `Please enter a valid email address`
                    : data.subtype === "tel"
                    ? `Please enter a valid ${
                        siteData.country === "NZ"
                          ? "New Zealand"
                          : siteData.country === "CA"
                          ? "Canadian"
                          : "Australian"
                      } phone number`
                    : data.subtype === "password"
                    ? "Password should have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                    : "",
                required: data.required,
              }}
              validate={isValidate}
              validationCallback={(res) => {
                this.setState({
                  [`has${data.name}Error`]: res,
                  isValidate: false,
                });
              }}
              fieldAttributes={{
                autoComplete: "off",
                type: data.subtype === "tel" ? "phone" : data.subtype,
                placeholder: data.placeholder,
                onChange: this.handleChange.bind(this, data.name, data),
                value: fields[data.name] || "",
                maxLength: data.maxlength,
              }}
              surveyLayout={surveyLayout}
              type={formtype}
              themecolors={themecolors.colors(
                theme,
                siteData.theme,
                siteFormData?.page_title
              )}
            />
          </div>
        );

      default:
        return <></>;
    }
  };

  postCodeAutoComplteOnChange = (value, data, field) => {
    //debugger;
    let { fields, selectedArr } = this.state;
    fields[field] = value;
    selectedArr = value;
    this.setState({
      fields,
      [`customddl-${field}`]: selectedArr,
      [`has${[field]}Error`]: false,
    });
  };

  comboChange = (value, data, field) => {
    let { fields, selectedArr } = this.state;
    fields[field] = value.value;
    selectedArr = value;
    this.setState({ fields, [`customddl-${field}`]: selectedArr });
  };

  PostZipAutoCompleteTag = (item, index, controltype) => {
    const { isValidate } = this.state;
    const { siteData, formtype } = this.props;
    const selectedArr = this.state[`customddl-${item.name}`];
    var theme = siteData.primary_color;
    var selectedArry = selectedArr === undefined ? "" : selectedArr;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];

    return (
      <>
        <div
          className="space-between"
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <AutoCompletePostSuburb
            type={formtype}
            options={item.values}
            name1={item.name}
            label={item.label}
            handleChange={(value) =>
              this.postCodeAutoComplteOnChange(value, item, item.name)
            }
            description={item.description}
            validateOptions={{
              check: true,
              required: item.required,
            }}
            allowMultiple={item.multiple}
            validate={isValidate}
            validationCallback={(res) => {
              this.setState({
                [`has${item.name}Error`]: res,
                isValidate: false,
              });
            }}
            value={selectedArry}
            requireValidOption={item.requireValidOption}
            theme_id={siteData.theme}
            iconName={item.icon}
            placeholder={item.placeholder}
            controltype={controltype}
            country={siteData.country}
            themecolors={themecolors.colors(
              theme,
              siteData.theme,
              siteFormData.page_title
            )}
          />
        </div>
      </>
    );
  };

  DropDownAutocompleteTag = (item, index) => {
    const { isValidate } = this.state;
    const { siteData, formtype } = this.props;

    const selectedArr = this.state[`customddl-${item.name}`];
    var theme = siteData.primary_color;
    var selectedArry =
      selectedArr === undefined
        ? item.values &&
          item.values.filter((inneritem) => {
            return inneritem.selected === true || inneritem.selected === "true";
          })[0]
        : selectedArr;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    return (
      <div
        className="space-between"
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DropDownAutocomplete
          type={formtype}
          options={item.values}
          name={item.name}
          label={item.label}
          handleChange={(value) => this.comboChange(value, item, item.name)}
          description={item.description}
          validateOptions={{
            check: true,
            required: true,
          }}
          allowMultiple={item.multiple}
          validate={isValidate}
          validationCallback={(res) => {
            this.setState({ [`has${item.name}Error`]: res, isValidate: false });
          }}
          value={selectedArry}
          requireValidOption={item.requireValidOption}
          theme_id={theme}
          iconName={item.icon}
          // theme_id={siteData.theme}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
        />
      </div>
    );
  };

  textAreaTag = (data, index) => {
    const { fields, isValidate } = this.state;
    const { theme_id, page_id, siteData } = this.props;
    var theme = this.props.siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    switch (data.subtype) {
      case "textarea":
        return (
          <div
            key={index}
            className={`space-between ${
              theme_id === "4" && page_id === "1" ? data.className : ""
            }`}
          >
            <TextArea
              theme_id={theme_id}
              theme={theme}
              key={index}
              label={data.label}
              placeHolder={data.placeholder}
              name={data.name}
              validateOptions={{
                required: data.required,
              }}
              className={data.className}
              iconName={data.icon}
              description={data.description}
              validate={isValidate}
              validationCallback={(res) =>
                this.setState({
                  [`has${data.name}Error`]: res,
                  isValidate: false,
                })
              }
              fieldAttributes={{
                autoComplete: "off",
                maxLength: data.maxlength,
                rows: data.rows,
                onChange: this.handleChange.bind(this, data.name, data),
                value: fields[data.name] || "",
                // maxLength: data.maxlength,
              }}
              themecolors={themecolors.colors(
                theme,
                siteData.theme,
                siteFormData.page_title
              )}
            />
          </div>
        );

      default:
        return <></>;
    }
  };

  handleSuccessCallback = (showProgress, otp) => {
    const { leadData, leadDatACALLBACK, siteData, setformstate } = this.props;
    const { fieldsData } = this.state;
    this.setState({ showOtpPop: false });
    showProgress && this.setState({ isLoading: true });
    if (
      fieldsData.domain === "biz.businessfundstoday.net" ||
      fieldsData.domain === "www.biz.businessfundstoday.net" ||
      fieldsData.domain === "v2.businessfundstoday.net" ||
      fieldsData.domain === "www.v2.businessfundstoday.net" ||
      fieldsData.domain === "businessloan.financesolver.co" ||
      fieldsData.domain === "www.businessloan.financesolver.co" ||
      fieldsData.domain === "native.financesolver.co" ||
      fieldsData.domain === "www.native.financesolver.co" ||
      fieldsData.domain === "bizau.businessfundstoday.net" ||
      fieldsData.domain === "www.bizau.businessfundstoday.net"
    ) {
      fieldsData.lead_description = `${otp}`;
    }
    if (siteData.theme === "2") {
      leadDatACALLBACK(fieldsData, this.handlecallback());
    } else {
      leadData(fieldsData);
    }
  };
  handlecallback = () => {
    const { fieldsData } = this.state;
    this.handleloading(true);
    // console.log("field+++",fieldsData);
    var userdata = {};
    if (fieldsData?.full_name) {
      const nameArr = fieldsData.full_name.split(" ");
      if (nameArr && nameArr.length > 0) {
        userdata.fname = nameArr[0];
      }
      if (nameArr && nameArr.length > 1) {
        userdata.lname = nameArr[1];
      }
    } else {
      userdata.fname = fieldsData?.fName;
      userdata.lname = fieldsData?.lName;
    }

    userdata.emailId = fieldsData?.email || "";
    userdata.phone = fieldsData?.phone_number || fieldsData?.phone || "";
    userdata.age = fieldsData?.age || 0;
    var postcodeArray =
      fieldsData.postcode &&
      fieldsData.postcode.includes(",") &&
      fieldsData?.postcode.split(", ");
    userdata.postcode =
      (postcodeArray && postcodeArray.length > 1 && postcodeArray[2]) || "";
    userdata.city =
      (postcodeArray && postcodeArray.length > 1 && postcodeArray[0]) ||
      fieldsData?.town_city ||
      "";
    userdata.state =
      (postcodeArray && postcodeArray.length > 1 && postcodeArray[1]) ||
      fieldsData?.state ||
      "";

    userdata.gender = fieldsData?.gender || fieldsData?.sex || "";
    userdata.address = fieldsData?.address || "";
    userdata.dob = fieldsData?.dob || null;
    localStorage.setItem("userdata", JSON.stringify(userdata));
    this.setState({ showAdvertly: true });
  };

  handleloading = (data) => {
    const { setformstate } = this.props;
    const { isLoading } = this.state;
    this.setState({ isLoading: data });
    if (data === false) {
      setformstate(true);
    }
  };

  render() {
    const { data, siteData, theme_id, formstate } = this.props;
    const { isLoading, showOtpPop, fieldsData, showAdvertly } = this.state;
    return (
      <>
        {showAdvertly && (
          <AdvertlyPlugin siteData={siteData} callback={this.handleloading} />
        )}
        {showOtpPop && (
          <Otpcard
            phoneval={fieldsData["phone_number"]}
            country={siteData.country}
            callback={this.handleSuccessCallback}
          />
        )}
        {theme_id === 2 && formstate === false && isLoading && <Card />}
        {theme_id === 2 && formstate === false && (
          <div className="dynamic-form">
            {data.map((item, index) => {
              switch (item.type) {
                case "header":
                  return this.headerTag(item, index);
                case "text":
                  return this.textTag(item, index);
                case "button":
                  return this.buttonTag(item, index);
                case "select":
                  return this.DynmicDropDownAutocomplete(item, index);
                case "autocomplete":
                  if (item.className.includes("postcode_suburb")) {
                    return this.PostZipAutoCompleteTag(
                      item,
                      index,
                      item.className
                    );
                  } else {
                    return this.DropDownAutocompleteTag(item, index);
                  }

                case "radio-group":
                  return this.radioboxTag(item, index);
                case "paragraph":
                  return this.paragraphTag(item, index);
                case "checkbox-group":
                  return this.checkboxTag(item, index);
                case "date":
                  return this.dateTag(item, index);
                // case "file":
                //   return this.fileTag(item, index);
                case "textarea":
                  return this.textAreaTag(item, index);
                case "number":
                  return this.numberFieldTag(item, index);
                default:
                  return <></>;
              }
            })}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  ManageSite: { isLoading, getSiteDataObject, getSiteStatus, getSiteError },
}) => ({ isLoading, getSiteDataObject, getSiteStatus, getSiteError });
export default connect(mapStateToProps, { leadDatACALLBACK, leadData })(
  DynamicForm
);

DynamicForm.defaultProp = {
  theme_id: 0,
  QBData: {},
  isFormClicked: false,
  heading: "",
  surveyLayout: "",
  formtype: "",
};
